import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
          light: '#ef5350',
          main: '#d32f2f',
          dark: '#c62828',
          contrastText: 'white',
        },
        secondary: {
          light: '#606061',
          main: '#383839',
          dark: '#050505',
          contrastText: 'white',
        },
        whiteBlack: {
          light: '#FFFFFF',
          main: '#FFFFFF',
          dark: '#E3E3E3',
          contrastText: '#1B1B1C',
        },
        blueSecondary:{
          main:"#1976d2",
          light:"#42a5f5",
          dark:"#1565c0",
          contrastText:"#fff"
        }




}});

export default theme;