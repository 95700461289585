import React, {createContext, useState, useEffect, useMemo, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { REACT_APP_API_URL } from '../../services/API/API';
import ErrorMessage from '../../features/ErrorMessage/ErrorMessage'
import { Box } from '@mui/material';
import Loader from '../../components/Loader/Loader';

export const AuthContext = createContext();

function AuthProvider({children}) {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null)
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)


  
  useEffect(()=>{
    const fetchUser = async()=>{
      const requestsOptions = {
        method: "GET",
        headers:{
          Authorization:"Bearer "+token
        }
      }
      const response = await fetch(`${REACT_APP_API_URL}/users/me`, requestsOptions)
      if(!response.ok){
        console.log("Hacer un error {Esto pasó en el contexto del usuario}")
        localStorage.removeItem("user")
        localStorage.removeItem("token")
    
      }else{
        const data = await response.json()
        setUser(data)
        // setTimeout(() => {
        //   setLoading(false)
        // }, 500);
        setLoading(false)
        localStorage.setItem("user",JSON.stringify(data))
      }
    
    
    };
    {
      token &&  
      fetchUser();
    }
  },[token])

  const navigate = useNavigate();

  const handleLogout = ()=>{
      setToken(null);
      setUser(null);
      setLoading(false);
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      navigate("/login");
    }

  const AuthAPI = async (username, password) =>{
    const requestsOptions = {
      method: "POST",
      headers:{"Content-Type":"application/x-www-form-urlencoded" },
      body:`grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`

    }
    const response = await fetch(`${REACT_APP_API_URL}/login`, requestsOptions)
    const data = await response.json()

    if(!response.ok){
      setLoading(false)
      return null
    }
    else{
      let token = data.access_token;
      return token;
    }

    };


const handleLogin = async (username, password)=>{
  if (!username || !password){
    // Añadir error de login
    console.log("Introduce valores válidos")
    setLoading(false)
  }
  else{
    const token = await AuthAPI(username, password);
    if(token){
      localStorage.setItem("token", token)
      setToken(token);
      setLoading(true);
      navigate("/home");
    }else{
      // Añadir error de login
      setShowError(true);
      setTimeout(()=>{setShowError(false)},3000)
      setLoading(false)
      console.log("Invalid credentials")
    }
  }

}

  const value= useMemo(()=>({
    token,
    setToken,
    handleLogin,
    handleLogout,
    user,
    loading

}),
[
  token,
  setToken,
  handleLogin,
  handleLogout,
  user,
  loading

]) 

  return (
    <AuthContext.Provider value={value}>
            { <Box position="relative" >  
                  <Loader  show={loading}/>       
                  <ErrorMessage message="Credenciales inválidas" show={showError} />
                  {children}          
              </Box>
              }
              
    </AuthContext.Provider>
  )
}

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);

