import React from 'react'
import { Alert } from '@mui/material'

function ErrorMessage({message, show}) {
  return (
    show &&
    <Alert 
    sx={{
      width:{lg:"700px", sm:"200px"},
      position:"absolute",
      left:"35%",
      marginLeft: "-50px",
      top: {lg:"80%", sm:"50%"},
      marginTop:{lg:"-50px",sm:"-10px"} 

    }}
    severity="error">{message}</Alert>
  )
}

export default ErrorMessage